@import 'global';

.ResponseReactions {
    position: relative;
    width: 110%;
    height: 100%;
    //background-color: yellow;
    z-index: 1000;
    opacity: 0;
    transition: .3s;
    left: 28px;

    &:hover {
        opacity: 1;
    }

    .reactions-layout {
        display: flex;
        padding-left: 25%;
        padding-top: 54px;
        padding-top: 32px;
        padding-top: 54px;
        height: 50%;
        align-items: center;
        justify-content: flex-end;
        //background-color: green;


        width: 100%;
        padding-left: 0px;
        padding-top: 0;
    }
}

// DEBT: We had to remove all this so that we can clone it and animate the emoji
//       see flingEmoji().
.reaction {
    //margin: 

    //opacity: .75;
    // min-width: 50%;
    // max-width: 50%;

    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;

    text-align: center;
    height: 120px;
    border-radius: 200px;
}

.reaction-img {
    margin-top: 90px;
    width: 40px !important;

    transition: .2s;
    border-radius: 200px;
}

.reaction.Bad {
    min-width: 50%;
    max-width: 50%;
    min-width: 25%;
    max-width: 25%;

    .reaction-img {
        transform: rotate(0deg);
        transform: rotate(0deg);
        transform: scale(.8);
        width: 50px;
        margin-left: -54px;
        margin-top: 94px;
        //margin-top: 70px;
        //padding-left: 100px !important;
        //margin-top: -1px;
        opacity: .5 !important;
        //opacity: 0 !important;
    }

    &:hover {
        .reaction-img {
            opacity: 1;
            transform: rotate(0deg) !important;
            //animation: horizontal-shaking 0.35s 1 !important;

            //box-shadow: 0px 0px 20px 0px #eee;
            //padding-left: 100px !important;
        }
    }
}

@keyframes horizontal-shaking {
    0% {
        transform: translateX(0)
    }

    25% {
        transform: translateX(5px)
    }

    50% {
        transform: translateX(-5px)
    }

    75% {
        transform: translateX(5px)
    }

    100% {
        transform: translateX(0)
    }
}

.reaction.Energized,
.reaction.Go {
    display: none;

    .reaction-img {
        margin-top: -3px;
        transform: scale(1.275);

        &:hover {
            opacity: 1;
            transform: scale(1.35);
        }
    }
}

.reaction.Happy {
    min-width: 75%;
    max-width: 75%;

    .reaction-img {
        //margin-top: 76px;
        margin-right: -18px;
        transform: rotate(32deg);
    }

    &:hover {
        .reaction-img {
            opacity: 1;
            transform: rotate(48deg);
            box-shadow: 0px 0px 20px 0px #ffd07e;
            box-shadow: 2px 2px 20px 10px lighten(rgba(#ffdc89, .8), 2%) !important;
        }
    }
}

.reaction.Happy .reaction-img:hover {
    transform: rotate(58deg) !important;
    //box-shadow: 2px 2px 20px 5px lighten(#ffdc89, 0%) !important;
}

.ResponseReactions.Completed {
    opacity: 0;
    transition: .3s;
}