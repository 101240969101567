@import 'global';

.ContentSliders {
    opacity: 0;
    position: fixed;
    left: 122px;
    bottom: -49px;

    width: 300px;
    height: 100px;

    left: -400px;
    top: 570px;
    top: 118px;

    top: -40px;
    top: 646px;
    left: 108px;

    top: 688px;
    left: 524px;

    top: 654px;
    left: 109px;

    width: 384px;
    padding: 8px 80px;
    //border: 2px solid #fff;
    //background-color: lighten(#fdf1d6, 7%);
    border-radius: 12px;
    z-index: 5000;
    //background-color: #fff;

    //box-shadow: 0.5px 0px 3px 1px rgba(0, 0, 0, .1);
    //transform: scale(.99);
    transform-origin: top left;

    .slider-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 18px;

        .label {
            min-width: 80px;
            max-width: 80px;

            font-size: 11pt;
            font-weight: 600;

            padding: 0 0px;

            //text-align: center !important;

            //color: $themeYellowMediumFontColor;

            color: #444;
        }

        .label-start {
            text-align: right;
        }

        .label-end {
            text-align: left;
        }

        .slider {
            min-width: 180px;
            max-width: 180px;
        }
    }

    .slider-row-specific {
        .rc-slider-rail {
            // background: linear-gradient(270deg, rgba(214, 134, 255, 1) 0%, rgba(217, 174, 247, 1) 12%, rgba(248, 251, 226, 1) 35%, rgba(246, 255, 254, 1) 50%, rgba(245, 237, 247, 1) 66%, rgba(217, 255, 245, 1) 87%, rgba(194, 255, 243, 1) 100%) !important;
            // background: linear-gradient(90deg, rgba(225, 168, 255, 1) 0%, rgba(232, 198, 255, 1) 12%, rgba(246, 255, 254, 1) 50%, rgba(201, 255, 241, 1) 87%, rgba(128, 230, 210, 1) 100%) !important;
            background: linear-gradient(90deg, rgba(255, 228, 134, 1) 0%, rgba(247, 242, 174, 1) 12%, rgba(248, 251, 226, 1) 35%, rgba(246, 255, 254, 1) 50%, rgba(245, 237, 247, 1) 66%, rgba(255, 217, 234, 1) 87%, rgba(255, 194, 217, 1) 100%);
            background: linear-gradient(90deg, rgba(255, 228, 134, 1) 0%, rgba(247, 242, 174, 1) 12%, rgba(248, 251, 226, 1) 35%, rgba(246, 255, 254, 1) 50%, rgba(237, 247, 245, 1) 66%, rgba(217, 255, 250, 1) 87%, rgba(162, 240, 225, 1) 100%) !important;
        }
    }


    $sliderPrimaryColor: desaturate(darken($themePinkBackgroundColor, 6%), 2%);
    $sliderHeight: 6.5px;

    .rc-slider-rail {
        background-color: darken(desaturate($themeYellowBackgroundColor, 8%), 14%);
        background: linear-gradient(270deg, rgba(247, 242, 174, 1) 0%, rgba(255, 255, 255, 1) 100%);
        background: linear-gradient(270deg, rgba(247, 242, 174, 1) 0%, rgba(228, 255, 253, 1) 50%, rgba(255, 217, 234, 1) 100%);
        background: linear-gradient(270deg, rgba(247, 242, 174, 1) 0%, rgba(248, 251, 226, 1) 35%, rgba(228, 255, 253, 1) 50%, rgba(245, 237, 247, 1) 66%, rgba(255, 217, 234, 1) 100%);
        background: linear-gradient(270deg, rgba(255, 228, 134, 1) 0%, rgba(247, 242, 174, 1) 12%, rgba(248, 251, 226, 1) 35%, rgba(228, 255, 253, 1) 50%, rgba(245, 237, 247, 1) 66%, rgba(255, 217, 234, 1) 87%, rgba(255, 194, 217, 1) 100%);
        background: linear-gradient(270deg, rgba(255, 228, 134, 1) 0%, rgba(247, 242, 174, 1) 12%, rgba(248, 251, 226, 1) 35%, rgba(246, 255, 254, 1) 50%, rgba(245, 237, 247, 1) 66%, rgba(255, 217, 234, 1) 87%, rgba(255, 194, 217, 1) 100%);

        //background-color: lighten($themeTealMediumFontColor, 46%);
        //background-color: lighten($themeTealMediumFontColor, 46%);
        height: $sliderHeight;
        /* Increase the height here */

        opacity: 1;
    }

    .rc-slider-track {
        background-color: $sliderPrimaryColor;
        background-color: transparent;
        height: $sliderHeight;
        /* Ensure the track height matches the rail height */
    }

    .rc-slider-handle {
        border: 3px solid lighten($sliderPrimaryColor, 30%);
        width: 20px;
        height: 20px;
        margin-top: -6.5px;
        /* Adjust the margin to center the handle */
        background-color: lighten($sliderPrimaryColor, 26%);

        border: 1.5px solid #eee;
        box-shadow: .5px .5px 1px 1px rgba(0, 0, 0, 0.2) !important;


        opacity: 1;
    }
}