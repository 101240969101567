@import 'global';

$labelColor: darken(#753a00, 0%);
//$labelColor: darken(hsl(200, 100%, 56%), 11%);
//$labelColor: darken($negativeColor, 10%);

.ResponseGrid {
    flex: 1;
    width: 860px;
    min-width: 860px;
    max-width: 860px;

    opacity: 1;
    margin-left: 60px;
    margin-left: -8px;
    margin-top: -8px;
    //margin-top: -68px !important;
    transition: .4s;
    transform: scale(.975);

    .grid-layout {
        display: flex;
        width: 860px;
        margin-left: -6px;

        .sheet {
            min-width: 840px;
            max-width: 840px;
        }

        .sheet-grid {
            display: flex;

            .options {
                min-width: 580px;
                max-width: 580px;
            }

            .voice-options-container {
                display: flex;
                // min-width: 208px !important;
                // max-width: 208px !important;
                margin: 188px 0 0 24px;
                margin: -108px 0 0 -270px;
                margin: 500px 0 0 -320px;
                margin: 190px 0 0 20px;
                //margin: -108px 0 0 -320px;
                transform: scale(1.5);
                height: 70px;
            }

            .voice-options-container-loading {
                min-width: 202px !important;
                max-width: 202px !important;
                margin: 9.5px 0 0 2px;
            }
        }

        .controls {
            width: 220px;
            min-width: 220px;
            max-width: 220px;
            height: 510px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            justify-items: center;
            align-items: flex-start;


        }

        .DoneButton,
        .CheckpointButton,
        .RefreshButton {

            margin: 0px 0 0 0px;

            width: 52px;
            height: 42px;


            //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

            padding: 14px 0;

            transform: scale(1.25);
            transform-origin: top center;
            transition: 0.2s;

            .refresh-fill {
                width: 52px;
                height: 52px;
                border-radius: 100px;

                //border: 2px solid darken($themeYellowBackgroundColor, 0%);

                background-color: darken($themeYellowBackgroundColor, 0.5%);
                background-color: #fff !important;
                border: 0px solid darken($themeYellowBackgroundColor, 38.5%);
                box-shadow: 0.5px .5px 1px 0px rgba(darken(#ebdac1, 16%), .85);
            }

            .refresh-icon-img {
                width: 22px;
                padding-top: 15px;
                align-self: center;
            }
        }

        .DoneButton {
            opacity: 0 !important;
            // opacity: .5;
            // margin-left: 20px;

            // margin: -200px 0 0 -128px;
            // margin: -200px 0 0 -276px;

            // margin: -200px 0 0 -126px;
            margin-left: 20px;
            //display: none;
        }

        .RefreshButtonLoading {
            margin: 0px 0 0 -2px;
            opacity: .65;
            transform: scale(1.20) !important;

            .refresh-fill {
                box-shadow: none !important;
            }
        }

        .DoneButton:hover,
        .RefreshButton:hover {
            cursor: pointer;
            transform: scale(1.2);


            .refresh-fill {
                background-color: darken($themeYellowBackgroundColor, 2.5%);
            }
        }
    }


    .sheet-heading {
        // padding: 24px 22px 5px 22px;
        // color: darken(#0ea5e4, 1%);
        // font-size: 11pt;
        // font-weight: 700;
        // text-align: left;
    }

    .category-heading {
        float: left;
        margin: 18px 0 2px -8px;
        //margin: 12px auto;
        padding: 0.7em 1.5em;
        background-color: #eee;

        font-size: 10pt;
        font-weight: 800;
        border-radius: 100px;

        background-color: #e2e3ff;
        color: lighten(#4447A1, 10%);

        background-color: lighten(#d7f3ff, 2%);
        color: darken(#29b0e7, 10%);

        background-color: darken(#29b0e7, 0%);
        color: #fff !important;
        zoom: 0.9;

        background-color: darken(#29c5e7, 0%);

        // color: darken(#29c5e7, 0%) !important;
        // background-color: transparent;

        opacity: 0;
        //display: none;


        // background-color: lighten(#f5ebd1, 7%);
        // color: darken(#f2c353, 20%);
        //color: #fff;
    }

    .respond-heading {
        padding: 40px 0 0 24px;
        color: darken(#f6db9e, 10%);
        font-family: $serifFamily;
        font-weight: 700;
        font-size: 19pt;
        text-align: left;
    }

    .options {
        //width: 1180px;
        width: 640px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: flex-start;

        transform: scale(1.05);
        transform-origin: top left;

        margin-left: 2px;
        margin-top: -2px;

        margin-top: -4px;

        //margin-top: 48px;
    }

    .LoadingOptions {
        //margin: 1.0px 0 0 6.5px;
    }
}

.ThemeColorPink .ResponseGrid .RefreshButton,
.ThemeColorPink .ResponseGrid .DoneButton {
    .refresh-fill {
        background-color: darken($themePinkBackgroundColor, 0.5%);
        border: 0px solid darken($themePinkBackgroundColor, 9.5%);
    }
}

.ThemeColorPurple .ResponseGrid .RefreshButton,
.ThemeColorPurple .ResponseGrid .DoneButton {
    .refresh-fill {
        background-color: darken($themePurpleBackgroundColor, 0.5%);
        border: 0px solid darken($themePurpleBackgroundColor, 9.5%);
    }
}

.ThemeColorPink .ResponseGrid .RefreshButton:hover,
.ThemeColorPink .ResponseGrid .DoneButton:hover {
    .refresh-fill {
        background-color: lighten($themePinkBackgroundColor, 0.5%) !important;
    }
}

.ThemeColorPurple .ResponseGrid .RefreshButton:hover,
.ThemeColorPurple .ResponseGrid .DoneButton:hover {
    .refresh-fill {
        background-color: darken($themePurpleBackgroundColor, 0%) !important;
    }
}

.ResponseGrid.ShowDoneButton {
    .DoneButton {
        opacity: 1;
    }
}

.ResponseGrid.HideDoneButton {
    .DoneButton {
        opacity: 0;
    }

    .DoneButton:hover {
        transform: scale(1.25);
    }
}

.label-good-find {
    display: none;
}

.IsStrongPress .label-good-find {
    font-size: 8.75pt;
    //font-family: 'Merriweather';
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    color: darken(#ff8d0d, 4%);
    color: darken($backgroundColor, 20%);
    color: darken(#04c2eb, 4%);
    opacity: .5;

    display: block !important;
    top: -180px;
    position: relative;
    text-align: center;
}

.CardDrop {
    width: 400px;
    height: 332px;
    border-radius: 28px;

    background-color: lighten(#d9efff, 2.5%);
    border: 3px dashed darken(#d9efff, 2%);

    background-color: lighten(#fff4d9, 5.5%);
    border: 3px dashed darken(#fff4d9, 4%);

    box-sizing: border-box;
}


.refresh-container {
    padding-right: 34px;
    transition: .3s all;
    cursor: pointer;
}

.refresh-container:hover {
    transform: scale(1.2);
    margin-top: 8px;
    margin-left: 8px;
}

.ResponseGridPage,
.ExploreSheetPage,
.ThoughtPostRatingPage {
    .chat-label {
        opacity: 0;
    }
}

.NegativeGridPage,
.ThoughtPreRatingPage {
    .chat-label {
        opacity: 1;
    }
}

.ShowMySessionTitle {
    .chat-label {
        opacity: 1 !important;
    }
}



.VoiceOptions {
    //display: flex;
    margin: 10px 0 2px -12px;

    .VoiceOption {
        background-color: lighten($backgroundColor, 2%);
        background-color: #fff;
        margin: 6px 4px;
        padding: 7px 16px;
        padding: 22.0px 24px;
        border-radius: 14px;

        color: lighten($voiceColor, 2%);
        font-weight: 600;
        font-size: 11pt;

        text-decoration: underline;
        text-decoration-color: lighten($voiceColor, 59%);
        text-decoration-thickness: 2.25px;

        text-align: left;

        opacity: .7;
    }

    .VoiceOption:hover {
        box-shadow: .75px .75px 1px 1px darken($backgroundColor, 3%);
        color: darken($voiceColor, 2%);
        cursor: pointer;
        //opacity: .75;

        text-decoration: underline;
        text-decoration-color: lighten($voiceColor, 46%);
        text-decoration-thickness: 2.25px;
    }

    .VoiceOption.IsSelected {
        color: lighten($voiceColor, 2%);
        background-color: #fff;
        text-decoration: underline;
        text-decoration-color: lighten($voiceColor, 46%);
        text-decoration-thickness: 2.25px;
        font-weight: 800;

        box-shadow: .75px .75px 1px 1px darken($backgroundColor, 3%);
        cursor: default !important;

        background-color: #f8feff;

        opacity: 1;
    }
}

.VoiceOptions.IsVoiceLoading {
    margin: -8px 0 2px -18px;


    .VoiceOption {
        opacity: .65;
    }

    .VoiceOption.IsSelected {
        opacity: 1 !important;
    }
}


// GREAT JOB ANIMATION
.animation-container {
    position: relative;
    top: 8px;
    left: 48px;
    z-index: 20;

    animation-duration: 1.75s;
    animation-name: slideHand;
    animation-iteration-count: 10;
    animation-direction: normal;
    animation-timing-function: linear;

    padding-top: 6px;
    padding-right: 12px;

    .illustration-img {
        width: 46px;
        align-self: center;

        animation-duration: 0.2s;
        animation-name: wiggle;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;
    }
}

.illustration {
    padding-top: 0px;
    animation-duration: 0.26s;
    animation-name: wiggleThinking;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;

    .illustration-img {
        width: 58px;
    }
}

.content-writing {
    .illustration {
        padding-top: 18px;

        .illustration-img {
            width: 52px;
        }
    }

    padding-top: 0px;
}


@keyframes wiggle {
    from {
        transform: rotate(0deg);

    }

    to {
        transform: rotate(2deg);
    }
}

@keyframes wiggleThinking {
    from {
        transform: rotate(0deg);

    }

    to {
        transform: rotate(7deg);
    }
}

@keyframes slideHand {
    from {
        margin-left: 0px;
    }

    to {
        margin-left: 6px;
    }
}

.HideResponseGrid .ResponseGrid {
    opacity: 0;
    margin-left: 40px;
}

.FadeIn {
    animation: contentFadeIn 0.6s ease 0s 1;
}

.FadeInDelay {
    animation-name: contentFadeIn;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: 1;
}

.FadeOut {
    transition: 0.5s all;
}

.FadingOut {
    .FadeOut {
        opacity: 0;
    }
}

@media (max-width: 1400px) {

    // .voice-options-container,
    // .voice-options-container-loading {
    //     min-width: 200px !important;
    //     max-width: 200px !important;
    // }
}

#cardClonesContainer {
    pointer-events: none;
}

.ResponseGrid,
#cardClonesContainer {
    .option {
        display: flex;
        width: 260px;
        height: 113.5px;
        //height: 128.5px;
        //height: 92px;
        box-sizing: border-box;

        font-size: 12.5pt;
        //font-size: 14.5pt;
        font-weight: 500;
        padding: 1.0em 38px 1.1em 24px;
        padding-top: .9em;
        padding-right: 20px;
        //padding-top: 0.85em;

        text-align: left;
        //text-align: center;
        line-height: 1.45em;

        color: darken(#0ea5e4, 1%);
        color: darken(#0ea5e4, 4%);
        color: #0ea2df;
        color: #04a8eb;
        //color: #222;
        //color: #135ec4;
        border-radius: 12px;
        border-radius: 2px;
        margin: 8px 8px 4px 0;
        margin: 10px 5px -5px 0;

        background-color: lighten(#d1eff7, 8%); // Was 10% for long time
        background-color: #fff;
        //background-color: transparent;

        background-color: #fefdee;

        background-size: 112px;
        background-position: 0 79px;
        //background-size: 0px;

        //background-color: #04a8eb;
        //pti: 1px 2px 3px 1px lighten(#e3e6f7, 2.75%);
        //box-shadow: 0px 0px 10px 10px lighten(#f8e9d3, 6%);
        box-shadow: 0.5px 1.5px 2px 0px rgba(darken(#ebdac1, 16%), .55);

        transition: 0.175s;
        transition: 0.3s;

        border-radius: 4px;
        //border: 2px solid darken($themeYellowBackgroundColor, 12%);

        //background-color: lighten(#04a8eb, 2%);

        //background-color: transparent;

        //height: 153.3px;

        padding: 0 !important;



        .checkmark-icon {
            width: 26px;
            max-width: 20px;
            min-width: 20px;
            margin-left: -26px;
            opacity: 0;
            padding: 0 4px 0 0;
            transition: 0.4s;

            .checkmark-img {
                width: 16px;
            }
        }

        .category-label {
            float: left;
            clear: both;
            font-size: 9.0pt;
            font-weight: 700;
            background-color: lighten(#ffae5e, 5%);
            color: #fff;
            padding: 2px 12px;
            border-radius: 50px;
            margin-left: -8px;
        }

        .label-category {
            display: none;
            font-size: 10.5pt;
            opacity: 0.7;
        }

        .Courage {
            background-color: lighten(#ffae5e, 5%);
        }

        .Grow {
            background-color: lighten(#4bf663, 1%);
        }

        .option-positioning {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .filler {
            position: relative;
            width: 100%;
            height: 100%;
            //background-color: #fff;
            border-radius: 12px;

        }

        .filler-flex {
            display: flex;
            width: 100%;
            height: 100%;
        }

        .filler-fill {
            //position: relative;
            align-self: flex-end;
            width: 100%;
            height: 100%;
            min-width: 100%;
            max-width: 100%;
            height: 0%;
            background-color: lighten(#d7f8ff, 5%);
            //background-color: lighten($positiveAccentColor, 46%);
            border-radius: 12px;

            transition: .7s;
        }

        .emoji-gradient-container {
            position: absolute;
            width: 200px;
            height: 110px;
            z-index: 1000;

            opacity: 0;
            transition: 1.5s;
        }

        .emoji-gradient {
            position: relative;
            top: 0px;
            width: 260px;
            height: 113.5px;
            border-radius: 4px;
            z-index: 1;
            transition: .3s;
            //opacity: 0 !important;

            background: linear-gradient(270deg, lighten(#d7f8ff, 5%) 0%, lighten(#d7f8ff, 5%) 50%, lighten(#d7f8ff, 5%) 100%);
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 50%, rgba(255, 233, 111, .25) 100%);
        }

        .label {
            top: -110px;
            height: 110px;
            position: relative;
            pointer-events: none;
            z-index: 2000;
            font-size: 12.25pt;
            font-size: 11.5pt;
            padding: 1.75em 60px;
            padding: 1.75em 50px;
            padding: 1.75em 30px;
            padding: 1.5em 20px 1.75em 20px;

            //padding-right: 2px;

            padding: 0;
            padding: 1.0em 38px 1.1em 24px;
            padding-top: .8em;
            padding-right: 26px;

            line-height: 1.45em;
            box-sizing: border-box;

            color: darken(#d1eff7, 72%);

            color: #372c49;
            color: #444;
            color: darken(#e75e47, 0%);
            color: #111;
            color: darken(#797dea, 5%);

            //color: darken(#e75e47, 20%);
            font-weight: 500;

            //opacity: 0.5;

            //background-color: #fff;

            //text-align: left;

            color: $neutralColor;
            color: $labelColor;
            //color: darken(#87c0ff, 22.5%);

            //font-family: 'Merriweather';
            //font-size: 10.75pt;
            //color: $positiveAccentColor;
            //color: darken($negativeColor, 42%) !important;


            //color: #444;
            //color: darken(#04c2eb, 7.5%);

            //color: darken(#fffdfa, 58%);

            //color: darken(#87d3ff, 28%);

            //color: darken(#1bd0f1, 8%);
            //color: $negativeColor;

            //color: #777;

            transition: .25s;

            text-align: left;
            //text-indent: -18px;
            //padding-left: 38px;

            text-decoration: none;
            text-decoration-thickness: 0px;
            text-decoration-color: lighten($neutralColor, 20%);
            transition: .55s;

            //font-family: $serifFamily;
            font-size: 11.25pt;
        }

        .label-good-find {
            display: none;
        }

        .icon-img {
            top: -144px;
            left: 250px;
            width: 18px;
            position: relative;
            opacity: 0;
        }
    }

    .option:hover {
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        box-shadow: 1px 1px 3px .5px rgba(darken(#ebdac1, 16%), .50);
        z-index: 2000;

        .label {
            text-decoration-color: lighten($neutralColor, 22%);
        }


        .emoji-gradient-container {
            opacity: 1 !important;
        }
    }

    .option.Filling {
        transition: 1.75s !important;
        transform: scale(1.25) !important;

        .filler-fill {
            height: 100%;
        }
    }

    .option.Flinging {
        .label {
            //font-family: 'Kalam' !important;
            font-size: 11.5pt !important;
        }
    }

    .option.MarkedBad {
        background-color: lighten($dropBackgroundColor, 0%) !important;
        box-shadow: none !important;
        transform: scale(1) !important;

        .option-positioning {
            transform: scale(0) !important;
            opacity: 0 !important;
            transition: .5s !important;
        }
    }

    .option.Selected {
        //opacity: 0;
        //background-color: darken($backgroundColor, 3%) !important;
        background-color: lighten($dropBackgroundColor, 0%) !important;
        box-shadow: none;
        transform: scale(1.00) !important;
        cursor: default !important;
        border-radius: 8px;

        .label {
            //color: lighten(#04a8eb, 10%);
            opacity: 0.35;
            opacity: 0.25;
            opacity: 0;


        }

        .option-positioning {

            .filler,
            .label {
                opacity: 0 !important;
            }

            .label-good-find {
                //display: block;
            }
        }

        .emoji-gradient-container {
            opacity: 0 !important;

            .emoji-gradient {
                opacity: 0 !important;
            }
        }

    }

    .option.Dropped {
        background-color: transparent !important;

        .checkmark-icon {
            opacity: 1;
        }
    }
}

.flying-card {
    pointer-events: none;
    animation: fling-animation 1.5s ease-in-out forwards;
    opacity: 1;
    /* You can adjust timing, easing, etc. here */
}

@keyframes fling-animation {
    0% {
        transform: translate(0, 0);
        opacity: 1;
    }

    40% {
        /* Move upward and forward: 
           Adjust these values as desired to create a nice arch */
        transform: translate(calc(var(--final-x) * 0.5), calc(var(--final-y) * -0.4));
        opacity: 1;
    }

    100% {
        /* End at the final destination */
        transform: translate(var(--final-x), var(--final-y));
        opacity: 0;
    }
}





@keyframes placeholderShimmer {
    0% {
        background-position: 68px 0;
    }

    33% {
        background-position: -68px 0;
    }

    66% {
        background-position: -268px 0;
    }
}

@keyframes placeholderShimmer2 {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 288px 0;
    }
}

.shimmer-container {
    position: absolute;
    width: 200px;
    height: 110px;
    z-index: 4000;
    //background-attachment: #eee;

    //opacity: 0;
    transition: 1.5s;

    pointer-events: none;
}

.shimmer-animation {
    position: relative;
    top: 0px;
    width: 280px;
    height: 113.5px;
    border-radius: 4px;
    z-index: 1;

    background-image: linear-gradient(to right,
            rgba(0, 0, 0, 0) 0%,
            rgba(255, 233, 111, .2) 50%,
            rgba(0, 0, 0, 0) 100%);
    background-repeat: no-repeat;
    //background-size: 800px 104px;
    animation-duration: .45s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: placeholderShimmer;
    animation-timing-function: ease-in-out;
    //animation-delay: .3s;
}

.shimmer-animation-2 {
    //top: -113px !important;
    animation-name: placeholderShimmer2 !important;
    //animation-duration: .85s !important;
    //z-index: 1 !important;
    //background-position: -68px !important;
    // background-image: linear-gradient(to right,
    //         rgba(0, 0, 0, 0) 0%,
    //         rgba(1, 1, 1, .2) 50%,
    //         rgba(0, 0, 0, 0) 100%);
}



.CheckpointButton {
    transform-origin: center !important;
    position: relative;
    top: 8px;
    left: 16px;
    //left: 100px;

    // top: -180px;
    // top: -200px;
    // top: 182px;
    // left: -178px;
    // left: -303px;
    transform: scale(1.35) !important;
    transform: scale(1.3) !important;

    &:hover {
        transform: scale(1.5) !important;

        .button-fill {
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }

    .button-fill {
        position: absolute;
        width: 82px;
        height: 82px;
        border-radius: 20px;
        margin: -18px 0 0 0px;

        //border: 2px solid darken($themeYellowBackgroundColor, 0%);

        //background-color: darken($themeYellowBackgroundColor, 0.5%);
        background-color: #fff !important;
        border: 0px solid darken($themeYellowBackgroundColor, 38.5%);
        box-shadow: 0.5px .5px 1px 0px rgba(darken(#ebdac1, 16%), .85);
    }

    .brain {
        position: relative;
        top: 16px;
        left: -9.5px;
        transform: scale(1.1);

        .brain-img {
            position: absolute;
            width: 28px;
            top: 12px;
            left: 24px;
        }

        .brain-sparkles {
            animation: shimmerEffect 4.5s ease-in-out infinite;
        }

        .brain-ray,
        .brain-sparkle-img {
            transform-origin: center !important;
            transition: opacity .3s, transform .5s !important;
        }

        .brain-ray-container,
        .brain-sparkle1-container,
        .brain-sparkle2-container,
        .brain-sparkle3-container,
        .brain-sparkle4-container {
            position: relative;
            transform-origin: center !important;
            transition: opacity .3s, transform .5s !important;
            transform: scale(1) translate(-10px, 10px);
        }

        .brain-ray-img {
            position: absolute;
            width: 38px;
            //transform-origin: bottom center;
            animation: rayEffect 4.5s ease-in-out infinite;
        }

        .brain-sparkle-img {
            position: absolute;
            //opacity: 0 !important;
            width: 28px;
            //transform-origin: bottom center;
            //animation: shimmerEffect 4.5s ease-in-out infinite;
        }

        .brain-sparkle2-img {
            position: absolute;
            //animation: shimmerEffect2 4.5s ease-in-out infinite;
        }

        .brain-sparkle3-img {
            position: absolute;
            transform: scale(.8);
            //animation: shimmerEffect1 4.5s ease-in-out infinite;
        }
    }

    .counter {
        //opacity: 0;
        position: relative;
        top: 29.5px;
        left: 18px;
        // top: 12px;
        // left: -12px;
        font-size: 15pt;
        font-weight: 400;
        color: $themeYellowMediumFontColor;
        text-align: center;
    }

    .daily-counter {
        display: flex;
        opacity: 0;
        position: relative;
        top: 32.5px;
        left: 9.0px;
        width: 63.5px;
        height: 12px;
        //border-radius: 100px;
        padding: 1px 1px;
        font-size: 7pt;
        font-weight: 600;
        //background-color: darken($themeYellowBackgroundColor, 1);
        //border: 1.5px solid darken($themeYellowBackgroundColor, 8%);
        color: $themeYellowMediumFontColor;
        text-align: center;
        transform: scale(.9);

        border-top: 1px solid darken($themeYellowBackgroundColor, 20%);
        border-bottom: 1px solid darken($themeYellowBackgroundColor, 20%);

        .today-label {
            font-size: 6pt;
        }

        .count {
            font-size: 6pt;
            margin-left: auto;
        }
    }
}

.ResponseCheckpoint {

    position: fixed;
    display: block;
    z-index: 5000;

    left: 576px;

    // min-width: 208px !important;
    // max-width: 208px !important;
    margin: 190px 0 0 140px;
    margin: 190px 0 0 -278px;
    //margin: -108px 0 0 -320px;
    transform: scale(1.75);
    //min-width: 300px;
    height: 70px;
    //border: 1px solid #ccc;

    .action-buttons {
        display: flex;
    }



    .checkpoint-button {
        display: flex;
        min-width: 160px;
        max-width: 160px;
        height: 88px;
        background-color: lighten($themePinkBackgroundShadowColor, 2.5%);
        //background-color: darken($themePinkBackgroundColor, 22);
        //background-color: #fff;
        border-radius: 10px;
        //border: 4px solid darken($themePinkBackgroundColor, 2%);
        //box-shadow: 3.5px 3.5px 12px 0px rgba(darken(#ebdac1, 16%), .45);
        box-shadow: 1.5px 1.5px 2px 0.5px rgba(darken(#ebdac1, 36%), .45);

        align-items: center;
        justify-content: center;

        .button-img {
            width: 28px;
        }



        .button-sparkle-img {
            position: relative;
            top: 20px;
            width: 28px;
            // margin-left: -30px;
            // margin-top: 10px;
            //transform-origin: bottom center;
            animation: shimmerEffect 3s ease-in-out infinite;
        }

        .button-sparkle2-img {
            // margin-left: -22px;
            // margin-top: 15px;
            animation: shimmerEffect2 3s ease-in-out infinite;
        }

        .button-sparkle3-img {
            // margin-left: -42px;
            // margin-top: 15px;
            animation: shimmerEffect3 3s ease-in-out infinite;
        }

        .button-done-img {
            animation: clapBounce2 1.75s ease-in-out infinite;
            animation-delay: .1s;
        }

        .button-clap-img {
            width: 28px;
            margin-left: -28px;
            margin-top: -18px;
            animation: clapBounce 1.75s ease-in-out infinite;
            animation-delay: .25s;
            //transform-origin: bottom center;
            //animation: shimmerEffect 3s ease-in-out infinite;
        }

        .button-label {
            padding: 0 0 0 6px;
            color: darken($themePinkMediumFontColor, 10%);
            //color: lighten($themePinkBackgroundColor, 0);
            font-weight: 700;
        }
    }

    .continue-button {
        margin-left: -79px;
    }

    .finish-button {
        margin-left: 4px;
    }

}

.brain-effect-imgs {
    position: relative;
    transform-origin: center;
    transition: .3s;
}


.BrainStage1 {
    .CheckpointButton .brain {
        .brain-ray-container {
            opacity: .6 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(.5) translate(-28px, 28px) !important;
        }

        .brain-sparkle1-container {
            opacity: 0 !important;
            transform: scale(1) translate(-12px, 12px) !important;
        }

        .brain-sparkle2-container {
            opacity: 0 !important;
        }

        .brain-sparkle3-container {
            opacity: 0 !important;
        }

        .brain-sparkle4-container {
            opacity: 0 !important;
        }
    }
}

.BrainStage2 {
    .CheckpointButton .brain {
        .brain-ray-container {
            opacity: .7 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(.6) translate(-26px, 21px) !important;
        }

        .brain-sparkle1-container {
            opacity: 1 !important;
            transform: scale(1) translate(-16px, 16px) !important;
        }

        .brain-sparkle2-container {
            opacity: 0 !important;
        }

        .brain-sparkle3-container {
            opacity: 0 !important;
        }

        .brain-sparkle4-container {
            opacity: 0 !important;
        }
    }
}

.BrainStage3 {
    .CheckpointButton .brain {
        .brain-ray-container {
            opacity: .8 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(.75) translate(-24px, 17px) !important;
        }

        .brain-sparkle1-container {
            opacity: 1 !important;
            transform: scale(1) translate(-18px, 10px) !important;
        }

        .brain-sparkle2-container {
            opacity: 1 !important;
            transform: scale(1) translate(-14px, 19px) !important;
        }

        .brain-sparkle3-container {
            opacity: 0 !important;
        }

        .brain-sparkle4-container {
            opacity: 0 !important;
        }
    }
}

.BrainStage4 {
    .CheckpointButton .brain {
        .brain-ray-container {
            opacity: .9 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(.85) translate(-23px, 13px) !important;
        }

        .brain-sparkles {
            animation: shimmerEffectIntense 3.5s ease-in-out infinite !important;
        }

        .brain-sparkle1-container {
            opacity: 1 !important;
            transform: scale(1) translate(-18px, 10px) !important;
            transform: scale(1) translate(-14px, 19px) !important;
        }

        .brain-sparkle2-container {
            opacity: 1 !important;
            transform: scale(1) translate(-14px, 19px) !important;
            transform: scale(1) translate(-18px, 10px) !important;
        }

        .brain-sparkle3-container {
            opacity: 1 !important;
            transform: scale(1) translate(-6px, 13px) !important;
        }

        .brain-sparkle4-container {
            opacity: 0 !important;
        }
    }
}

.BrainStage5 {
    .CheckpointButton .brain {
        .brain-ray-container {
            opacity: 1 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(.95) translate(-23px, 10.5px) !important;
        }

        .brain-sparkles {
            animation: shimmerEffectIntense 3.5s ease-in-out infinite !important;
        }

        .brain-sparkle1-container {
            opacity: 1 !important;
            transform: scale(1) translate(-18px, 10px) !important;
            //transform: scale(1) translate(-14px, 19px) !important;
        }

        .brain-sparkle2-container {
            opacity: 1 !important;
            transform: scale(1) translate(-14px, 17px) !important;
            //transform: scale(1) translate(-18px, 10px) !important;
        }

        .brain-sparkle3-container {
            opacity: 1 !important;
            transform: scale(1) translate(-12px, 10px) !important;
        }

        .brain-sparkle4-container {
            opacity: 1 !important;
            transform: scale(1) translate(-6px, 10px) !important;
        }
    }
}

.BrainAnimationExpand,
.BrainAnimationSinkIn,
.BrainAnimationHeartBeat,
.BrainAnimationEnding {
    .CheckpointButton {
        transform: scale(1.5) !important;

        .button-fill {
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }
}

.BrainAnimationShrink {
    .CheckpointButton .brain {
        .brain-sparkles {
            animation: shimmerEffect 10s ease-in-out infinite !important;
        }

        .brain-ray-container {
            opacity: 1 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(1.2) translate(-21px, 5px) !important;
        }

        .brain-ray-img {
            animation: none !important;
        }

        .brain-sparkle1-container,
        .brain-sparkle2-container,
        .brain-sparkle3-container,
        .brain-sparkle4-container {
            transition: opacity .2s, transform .2s !important;

        }

        .brain-sparkle2-img,
        .brain-sparkle3-img {
            animation: shakeEffect 0.45s infinite !important;
        }


        .brain-sparkle1-img,
        .brain-sparkle4-img {
            animation: shakeEffect2 0.35s infinite !important;
        }



        .brain-sparkle1-container {
            //opacity: 1 !important;
            transform: scale(1.2) translate(-14px, 13px) !important;
            //transform: scale(1) translate(-14px, 19px) !important;
        }

        .brain-sparkle2-container {
            //opacity: 1 !important;
            transform: scale(1.2) translate(-12px, 12px) !important;
            //transform: scale(1) translate(-18px, 10px) !important;
        }

        .brain-sparkle3-container {
            //opacity: 1 !important;
            transform: scale(1.2) translate(-13px, 9px) !important;
        }

        .brain-sparkle4-container {
            //opacity: 1 !important;
            transform: scale(1.2) translate(-9px, 10px) !important;
        }
    }
}

.BrainAnimationExpand {
    .CheckpointButton .brain {
        .brain-sparkles {
            animation: shimmerEffect 10s ease-in-out infinite !important;
        }

        .brain-ray-container {
            opacity: .8 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(.6) translate(-26px, 19.5px) !important;
            transition: 1s !important;
        }

        .brain-ray-img {
            animation: none !important;
        }

        .brain-sparkle1-container,
        .brain-sparkle2-container,
        .brain-sparkle3-container,
        .brain-sparkle4-container {
            transition: opacity .2s, transform .2s !important;

        }

        .brain-sparkle2-img,
        .brain-sparkle3-img {
            animation: none !important;
        }

        .brain-sparkle1-img,
        .brain-sparkle4-img {
            animation: none !important;
        }

        .brain-sparkles {
            transform: rotate(90deg) !important;
            transition: rotate 1s !important;
        }

        .brain-sparkle1-container {
            //opacity: 1 !important;
            transform: scale(1.2) translate(-24px, 11px) rotate(-30deg) !important;
            transition: transform .5s !important;
            //transform: scale(1) translate(-14px, 19px) !important;
        }

        .brain-sparkle2-container {
            //opacity: 1 !important;
            transform: scale(1.2) translate(-4px, 9px) rotate(-20deg) !important;
            transition: transform .5s !important;
            //transform: scale(1) translate(-18px, 10px) !important;
        }

        .brain-sparkle3-container {
            //opacity: 1 !important;
            transform: scale(1.2) translate(-22px, 21px) rotate(-20deg) !important;
            transition: transform .5s !important;
        }

        .brain-sparkle4-container {
            //opacity: 1 !important;
            transform: scale(1.2) translate(-4px, 21px) rotate(-20deg) !important;
            transition: transform .5s !important;
        }
    }
}



@keyframes heartbeat {
    0% {
        transform: scale(1.1);
    }

    20% {
        transform: scale(1.135);
    }

    40% {
        transform: scale(1.1);
    }

    60% {
        transform: scale(1.135);
    }

    80% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1.1);
    }
}

.BrainAnimationHeartBeat,
.BrainAnimationSinkIn {
    .CheckpointButton .brain {
        .brain-sparkles {
            animation: shimmerEffect 10s ease-in-out infinite !important;
            transition: opacity 2s, transform 0.5s !important;
            opacity: 0 !important;
        }

        .brain-ray-container {
            opacity: 1 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(1.2) translate(-21px, 5px) !important;
        }

        .brain-ray-img {
            animation: none !important;
        }

        .brain-sparkle1-container,
        .brain-sparkle2-container,
        .brain-sparkle3-container,
        .brain-sparkle4-container {
            transition: opacity 2s, transform .2s !important;
        }

        // .brain-sparkle2-img,
        // .brain-sparkle3-img {
        //     animation: shakeEffect 0.45s infinite !important;
        // }


        // .brain-sparkle1-img,
        // .brain-sparkle4-img {
        //     animation: shakeEffect2 0.35s infinite !important;
        // }



        .brain-sparkle1-container {
            //opacity: 0 !important;
            transform: scale(1.2) translate(-14px, 13px) !important;
            transition: transform 0.35s !important;
            //transform: scale(1) translate(-14px, 19px) !important;
        }

        .brain-sparkle2-container {
            //opacity: 0 !important;
            transform: scale(1.2) translate(-12px, 12px) !important;
            transition: transform 0.35s !important;
            //transform: scale(1) translate(-18px, 10px) !important;
        }

        .brain-sparkle3-container {
            //opacity: 0 !important;
            transform: scale(1.2) translate(-13px, 11px) !important;
            transition: transform 0.35s !important;

        }

        .brain-sparkle4-container {
            //opacity: 0 !important;
            transform: scale(1.2) translate(-11px, 10px) !important;
            transition: transform 0.35s !important;
        }
    }
}

.BrainAnimationHeartBeat {
    .CheckpointButton .brain {
        animation: heartbeat .85s ease-in-out 1 !important;
        transform-origin: center center;

        .brain-sparkle1-container,
        .brain-sparkle2-container,
        .brain-sparkle3-container,
        .brain-sparkle4-container {
            opacity: 0 !important;
        }

        .brain-ray-container {
            opacity: .5 !important; // Because of CSS animations overriding despite cascading rules
            transform: scale(1.2) translate(-21px, 5px) !important;
            transition: opacity 1s, transform .5s !important;
        }

        .brain-ray-img {
            animation: none !important;
        }
    }
}

@keyframes shakeEffect {
    0% {
        transform: translate(0, 0)
    }

    25% {
        transform: translate(1px, 0)
    }

    50% {
        transform: translate(-.5px, .5px)
    }

    75% {
        transform: translate(0, -.5px)
    }

    100% {
        transform: translate(0, 0)
    }
}

@keyframes shakeEffect2 {
    0% {
        transform: translate(0, 0)
    }

    25% {
        transform: translate(.5px, .5px)
    }

    50% {
        transform: translate(-.5px, 1.5px)
    }

    75% {
        transform: translate(0px, -.5px)
    }

    100% {
        transform: translate(0, 0)
    }
}


// .BrainStage4 {
//     .CheckpointButton .brain {
//         .brain-effect-imgs {
//             top: 1px !important;
//             left: .5px;
//             transform: scale(1);

//         }

//         .brain-ray-img {}

//         .brain-sparkle-img {
//             //opacity: 0 !important;
//             //transform: scale(.7);
//         }

//         .brain-sparkle2-img {
//             //opacity: 0 !important;
//         }

//         .brain   -sparkle3-img {
//             //opacity: 0 !important;
//         }
//     }
// }

// .BrainStage1 {
//     .CheckpointButton .brain {
//         .brain-ray-img {
//             //opacity: .6 !important; // Because of CSS animations overriding despite cascading rules
//             //transform: scale(.5) !important;
//         }

//         .brain-sparkle-img {
//             opacity: 0 !important;
//         }

//         .brain-sparkle2-img {
//             opacity: 0 !important;
//         }

//         .brain-sparkle3-img {
//             opacity: 0 !important;
//         }
//     }
// }

// .BrainStage2 {
//     .CheckpointButton .brain {
//         .brain-effect-imgs {
//             top: 8px !important;
//             left: -1.5px;
//             transform: scale(.5);
//         }

//         .brain-ray-img {}

//         .brain-sparkle-img {
//             //opacity: 0 !important;
//             //transform: scale(.7);
//         }

//         .brain-sparkle2-img {
//             opacity: 0 !important;
//         }

//         .brain-sparkle3-img {
//             opacity: 0 !important;
//         }
//     }
// }


// .BrainStage3 {
//     .CheckpointButton .brain {
//         .brain-effect-imgs {
//             top: 3px !important;
//             left: -.5px;
//             transform: scale(.8);

//         }

//         .brain-ray-img {}

//         .brain-sparkle-img {
//             //opacity: 0 !important;
//             //transform: scale(.7);
//         }

//         .brain-sparkle2-img {
//             //opacity: 0 !important;
//         }

//         .brain-sparkle3-img {
//             opacity: 0 !important;
//         }
//     }
// }

// .BrainStage4 {
//     .CheckpointButton .brain {
//         .brain-effect-imgs {
//             top: 1px !important;
//             left: .5px;
//             transform: scale(1);

//         }

//         .brain-ray-img {}

//         .brain-sparkle-img {
//             //opacity: 0 !important;
//             //transform: scale(.7);
//         }

//         .brain-sparkle2-img {
//             //opacity: 0 !important;
//         }

//         .brain-sparkle3-img {
//             //opacity: 0 !important;
//         }
//     }
// }

// .BrainAnimationExpand {
//     .CheckpointButton .brain {
//         .brain-effect-imgs {
//             top: 1px !important;
//             left: .5px;
//             transform: scale(1);


//         }

//         .brain-sparkle-img {}

//         .brain-ray-img {}

//         .brain-sparkle-img {
//             //opacity: 0 !important;
//             //transform: scale(.7);

//             top: 8px !important;
//             left: 16px !important;
//             animation-delay: 1s !important;
//         }

//         .brain-sparkle1-img {
//             animation: expandSpinReverse 3.5s ease-in-out infinite !important;
//         }

//         .brain-sparkle2-img {
//             top: 30px !important;
//             left: 18px !important;
//             animation: expandSpinReverse2 3.5s ease-in-out infinite;

//         }

//         .brain-sparkle3-img {
//             top: 4px !important;
//             left: 30px !important;
//             animation: expandSpin 3.5s ease-in-out infinite;

//         }
//     }
// }

// .BrainAnimationExpand {
//     .CheckpointButton .brain {
//         .brain-effect-imgs {
//             top: 1px !important;
//             left: .5px;
//             transform: scale(1);
//         }

//         .brain-sparkle-img {}

//         .brain-ray-img {}

//         .brain-sparkle-img {
//             //opacity: 0 !important;
//             //transform: scale(.7);


//             //animation-delay: 1s !important;
//         }

//         .brain-sparkle1-img {
//             //animation: expandSpinReverse 3.5s ease-in-out infinite !important;
//             transform: translate(17px, 11px) !important;
//             transform: translate(0px, 0px) !important;
//         }

//         .brain-sparkle2-img {
//             //animation: expandSpinReverse2 3.5s ease-in-out infinite;
//             transform: translate(20px, 24px) !important;
//             transform: translate(0px, 0px) !important;

//         }

//         .brain-sparkle3-img {
//             //animation: expandSpin 3.5s ease-in-out infinite;
//             transform: translate(30px, 4px) !important;
//             transform: translate(0px, 0px) !important;

//         }
//     }
// }

.BrainAnimationRotate {
    .CheckpointButton .brain {
        .brain-effect-imgs {
            top: 1px !important;
            left: .5px;
            transform: scale(1);
        }

        .brain-sparkle-img {
            transform: rotate(180deg) !important;
            transition: 2s !important;
        }

        .brain-sparkle1-img {
            //animation: expandSpinReverse 3.5s ease-in-out infinite !important;
            top: 11px !important;
            left: 17px !important;
            //transform: rotate(180deg) !important;
            //transition: 2s !important;
        }

        .brain-sparkle2-img {
            top: 24px !important;
            left: 20px !important;
            // transform: rotate(180deg) !important;
            // transition: 2s !important;
            //animation: expandSpinReverse2 3.5s ease-in-out infinite;

        }

        .brain-sparkle3-img {
            top: 4px !important;
            left: 30px !important;

            //transition: 2s !important;
            //animation: expandSpin 3.5s ease-in-out infinite;

        }
    }
}

// .BrainAnimationShrink {
//     .CheckpointButton .brain {
//         .brain-effect-imgs {
//             top: 1px !important;
//             left: .5px;
//             transform: scale(1);


//         }

//         .brain-sparkle-img {
//             transform: rotate(180deg) !important;
//             transition: .3s !important;
//         }

//         .brain-ray-img {}

//         .brain-sparkle-img {
//             //opacity: 0 !important;
//             //transform: scale(.7);

//             transition: .3s !important;

//         }

//         .brain-sparkle1-img {
//             top: 18px !important;
//             left: 26px !important;

//             top: 2px !important;
//             left: 17px !important;
//             opacity: 1 !important;
//             transform: rotate(90deg) !important;
//             transform: rotate(180deg) !important;
//         }

//         .brain-sparkle2-img {
//             top: 30px !important;
//             left: 18px !important;
//             top: 18px !important;
//             left: 26px !important;

//             top: 4px !important;
//             left: 18px !important;
//             opacity: 1 !important;
//             transform: none !important;
//             transform: rotate(180deg) !important;
//             //animation: expandSpinReverse2 3.5s ease-in-out infinite;

//         }

//         .brain-sparkle3-img {
//             top: 4px !important;
//             left: 30px !important;
//             top: 18px !important;
//             left: 26px !important;

//             top: 2px !important;
//             left: 16px !important;
//             opacity: 1 !important;
//             transform: none !important;
//             transform: rotate(180deg) !important;
//             //animation: expandSpin 3.5s ease-in-out infinite;

//         }
//     }
// }


.checkpoint-overlay {
    position: fixed;
    bottom: -400px;
    left: -1000px;
    width: 2400px;
    height: 800px;
    background: linear-gradient(to top, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
    border-radius: 20px;
    z-index: 4999;
}

.ResponseFeedback {

    margin-left: -180px;
    margin-top: -22px;
    margin-top: -20px;

    padding: 0 12px;
    transform: scale(.725);

    .feedback-heading {
        font-size: 10pt;
        font-weight: 700;
        text-align: left;
        color: $themePinkFontColor;
    }

    .feedback-buttons {
        margin-top: -12px;
        margin-left: 12px;
        width: 600px;
    }

    .feedback-button {
        float: left;
        flex: 0 0 auto;
        background-color: saturate(lighten($themePinkBackgroundShadowColor, 2), 50);
        border-radius: 100px;
        margin: 4.5px 2px !important;
        padding: 16px 24px;
        color: lighten($themePinkFontColor, 12);
        font-weight: 600;
        font-size: 9.5pt;
        box-shadow: 1.5px 1.5px 2px 1px rgba(darken(#ebdac1, 16%), .45);
    }

    .feedback-button-better {
        background-color: saturate(darken($themeYellowBackgroundShadowColor, 8), 80);
        background-color: lighten(#ffee79, 0);
        background-color: lighten(#fff4a8, 0);
        font-weight: 700;
        color: saturate(lighten($themeYellowFontColor, 10), 30);
    }

    .feedback-button-better-secondary {
        background-color: lighten(#fff4a8, 8);
        font-weight: 700;
        color: saturate(lighten($themeYellowFontColor, 10), 30);
    }

    .feedback-button-option {
        background-color: lighten($themePurpleBackgroundShadowColor, 1);
        color: desaturate(lighten($themePurpleFontColor, 16), 30);
    }
}

@keyframes expandSpin {
    0% {
        // transform: scale(1.1);
        // opacity: 0.65;
        transform: rotate(5deg);
    }

    50% {
        //transform: scale(1.4);
        // opacity: 1;
        transform: rotate(90deg);
    }

    100% {
        // transform: scale(1.1);
        // opacity: 0.65;
        transform: rotate(55deg);
    }
}

@keyframes expandSpinReverse {
    0% {
        // transform: scale(1.1);
        // opacity: 0.65;
        transform: rotate(5deg);
    }

    50% {
        //transform: scale(1.4);
        // opacity: 1;
        transform: rotate(-100deg);
    }

    100% {
        // transform: scale(1.1);
        // opacity: 0.65;
        transform: rotate(55deg);
    }
}

@keyframes expandSpinReverse2 {
    0% {
        // transform: scale(1.1);
        // opacity: 0.65;
        transform: rotate(-220deg);
    }

    50% {
        //transform: scale(1.4);
        // opacity: 1;

        transform: rotate(-120deg);
    }

    100% {
        // transform: scale(1.1);
        // opacity: 0.65;
        transform: rotate(-220deg);
    }
}

@keyframes rayEffect {
    0% {
        transform: scale(1.1);
        opacity: 0.65;
    }

    50% {
        transform: scale(1.4);
        opacity: 1;
    }

    100% {
        transform: scale(1.1);
        opacity: 0.65;
    }
}

@keyframes shimmerEffect {
    0% {
        transform: scale(1) rotate(0deg);
        //opacity: 0.45;
    }

    50% {
        transform: scale(1.1) rotate(6deg);
        //opacity: 0.8;
    }

    100% {
        transform: scale(1) rotate(0deg);
        //opacity: 0.45;
    }
}

@keyframes shimmerEffectIntense {
    0% {
        transform: scale(1.1) rotate(0deg);
        //opacity: 0.45;
    }

    50% {
        transform: scale(1.3) rotate(9deg);
        //opacity: 0.8;
    }

    100% {
        transform: scale(1.1) rotate(0deg);
        //opacity: 0.45;
    }
}

@keyframes shimmerEffect2 {
    0% {
        transform: scale(1.2) rotate(0deg);
        opacity: 0.3;
    }

    50% {
        transform: scale(1.5) rotate(-12deg);
        opacity: 0.8;
    }

    100% {
        transform: scale(1.2) rotate(0deg);
        opacity: 0.3;
    }
}

@keyframes shimmerEffect3 {
    0% {
        transform: scale(1.3) rotate(-4deg);
        opacity: 0.3;
    }

    50% {
        transform: scale(1.6) rotate(4deg);
        opacity: 0.8;
    }

    100% {
        transform: scale(1.3) rotate(-4deg);
        opacity: 0.3;
    }
}


@keyframes clapBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }

    40% {
        transform: translateY(-6px) rotate(15deg);
    }

    60% {
        transform: translateY(-2px) rotate(0deg);
    }
}

@keyframes clapBounce2 {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0) rotate(0deg);
        opacity: 1;
    }

    40% {
        transform: translateY(-6px) rotate(8deg);
    }

    60% {
        transform: translateY(-2px) rotate(0deg);
    }
}

.BrainScores {
    opacity: 0;
    transform: translateY(40px) scale(.5);
    transform-origin: center center;
    transition: .5s;

    position: relative;
    top: -48px;
    left: -22px;
    z-index: 0;
    width: 100px;
    height: 60px;
    border-radius: 18px;

    background-color: lighten(#feffcc, 4.5);
    border: 0px solid darken($themeYellowBackgroundColor, 38.5%);
    box-shadow: 0.5px .5px 1px 0px rgba(darken(#ebdac1, 16%), .85);

    .scores {
        padding: 11px 12px;
    }

    .BrainScore {
        display: flex;
        padding: 1.5px 0 .5px 0;
        border-bottom: .5px solid darken($themeYellowBackgroundShadowColor, 12);

        .score-label {
            font-size: 5.25pt;
            font-weight: 600;
        }

        .score-value {
            margin-left: auto;
            margin-top: 0px;
            font-size: 5.5pt;
            font-weight: 700;
            color: #3b3732 !important;
            //opacity: .5;
        }
    }

    .BrainScore.lateral-thinking,
    .BrainScore.growth-mindset,
    .BrainScore.open-mindedness,
    .BrainScore.future-oriented {

        .score-label,
        .score-value {
            color: darken(#3eafc0, 5);
        }
    }

    .BrainScore.self-love,
    .BrainScore.self-motivation {

        .score-label,
        .score-value {
            color: desaturate(lighten($themePurpleFontColor, 24), 30);
        }
    }

    .BrainScore.open-mindedness,
    .BrainScore.intentionality,
    .BrainScore.decision-making,
    .BrainScore.problem-solving {

        .score-label,
        .score-value {
            color: darken(#d8a255, 8);
        }
    }
}

.HasBrainScores .BrainScores {
    opacity: 1;
    transform: translateY(0px) scale(.9);
}