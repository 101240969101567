@import 'global';

.SessionControls {

    position: fixed;
    left: 652px;
    bottom: -39px;

    width: 384px;
    padding: 8px 14px;

    .session-done-button {
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 324px;
        height: 100%;
        background-color: darken($themeYellowBackgroundColor, 10%);
        border-radius: 12px;
        padding: 22px;
        cursor: pointer;
        transition: background-color 0.2s;

        font-size: 13pt;
        font-weight: 700;
        color: darken($themeYellowMediumFontColor, 8%);
        transition: .2s;

        &:hover {
            background-color: darken($themeYellowBackgroundColor, 12%);
            transform: scale(1.045);

        }
    }
}