@import 'global';

.ProgressGrid {
    //display: none;
    transition: .6s !important;
}


.ProgressGrid .ProgressRow .ProgressCard {
    margin: 6px 7.5px !important;
    padding: 11.5px 16px !important;
    border-radius: 5px;
    width: 152px !important;
    height: 114px;
    height: 116px;
    transform: scale(1.06);

    .ProgressCounter {
        opacity: 1 !important;

        margin-top: 3px;
        margin-right: -16.5px;
        margin-top: 8px;
        //background-color: #fff;
        border-radius: 100px;
        //box-shadow: 0 0 2px 0 rgba(darken(#ebdac1, 36%), .50);
        transform: scale(.915);
        transform: scale(.995);
    }

    .GreatJobMessage {
        position: relative;
        top: -25px;
        left: -11px;
        min-width: 320px;
        max-width: 320px;
        height: 20px;
        border-radius: 100px;
        //background-color: #fff;
        //box-shadow: 0 0 2px 0 rgba(darken(#ebdac1, 36%), .50);
        padding: 2px;
        //transform: translateY(0) rotate(0deg);
        //opacity: 1;
        animation: messageBounce 1.2s ease-in-out 1;


        .message-label {
            min-width: 320px;
            max-width: 320px;
            padding: 0px 0 0 8px;
            color: $themeYellowMediumFontColor;
            font-weight: 700;
            font-size: 9pt;

        }
    }

    .label {
        padding-top: 2.5px;
        margin-left: 0px;
        margin-top: 4.3.5px;

        .label-content {
            font-size: 10.5pt !important;
            line-height: 1.45em !important;
            //font-weight: 700;
            font-family: $sansFamily !important;
            font-weight: 500;
        }
    }

    .progress-fill {
        //display: none;
        position: absolute;

        height: 40px;
        width: 100%;
        //border-top: 2px solid darken($themeYellowBackgroundColor, 10%);
        border-radius: 0 0 8px 8px;
        border-radius: 0 0 8px 8px !important;
        bottom: 0px;
        right: 0;

        transition: .85s height;
    }

    .progress-fill-2 {
        display: none;
        //display: block;
        left: 0;
        background: linear-gradient(270deg, saturate(darken($themeYellowBackgroundColor, 3), 2) 0%, lighten($themeYellowBackgroundColor, 2%) 50%) !important;
    }
}

.IsProgressFillEmpty .progress-fill {
    opacity: 0 !important;
}

.ProgressCard-container {
    position: relative;
}

.ProgressCard.IsNewPlaceholder {
    opacity: 0 !important;

    .OpenPlaceholder {
        opacity: 1 !important;
    }
}

.ProgressFlower {
    opacity: 0;
    position: absolute;
    bottom: 14px;
    left: 14px;

    width: 26px;
    height: 26px;
    transform: scale(1.15);

    background-color: #fff;
    border-radius: 200px;
    border: 1.5px solid saturate(darken($themePinkBackgroundShadowColor, 3%), 10%);
    border: 1.5px solid #eee;
    border: 0;
    //box-shadow: .5px .5px 2px .5px rgba(darken(#ebdac1, 16%), .50);
    background-color: transparent;

    text-align: center;

    .flower-img {
        align-self: center;
        margin: 4.5px 0 0 0;
        width: 15px;
        height: 15px;
        transform: scale(2);
    }
}

.FlowerStage1 {
    .ProgressFlower {
        transform: scale(1.0);

        .flower-img {}
    }
}

.FlowerStage2 {
    .ProgressFlower {
        transform: scale(1.15);

        .flower-img {

            margin-top: 4px;
        }
    }
}


.FlowerStage3 {
    .ProgressFlower {
        transform: scale(1.35);

        .flower-img {

            margin-top: 5px;
        }
    }
}

.FlowerStage1.ColorPurple .ProgressFlower {
    .flower-img {
        transform: scale(1.2);
        margin: 4px 0 0 2px;
    }
}

$fillBorderWidth: 0;
$fillDeg: 180deg;

.ProgressCard.ColorYellow {
    $borderColor: saturate(darken($themeYellowBackgroundShadowColor, 10%), 2%);
    $fontColor: saturate(darken($themeYellowMediumFontColor, 8%), 10%);
    $fontColor: saturate(lighten($themeYellowFontColor, 3.5%), 0%);
    $progressFillColor: lighten($themeYellowBackgroundColor, .5%);

    border: 2px solid $borderColor;


    .label {

        .label-content {
            color: $fontColor !important;
        }
    }

    .progress-fill {
        float: right;
        border-radius: 0 !important;
        background-color: $progressFillColor;
        background: linear-gradient($fillDeg, saturate(darken($themeYellowBackgroundColor, 5.0), 2) 0%, lighten($themeYellowBackgroundColor, 2%) 75%);
        //background: darken($themeYellowBackgroundColor, 2%) !important;
        //border-top: 4.5px solid desaturate(darken($themeYellowBackgroundColor, 15), 15);
        border-top: 2px solid saturate(darken($themeYellowBackgroundColor, 18), 2);
    }
}

.ProgressCard.ColorPink {
    $borderColor: saturate(darken($themePinkBackgroundShadowColor, 3%), 10%);
    $fontColor: saturate(lighten($themePinkFontColor, 8%), 0%);
    $progressFillColor: lighten($themePinkBackgroundColor, 0.5%);

    //background-color: lighten($themePinkBackgroundColor, .5%) !important;
    border: 2px solid $borderColor;

    .label {
        .label-content {
            color: $fontColor !important;
        }
    }

    .progress-fill {
        border-radius: 0px 0 0 0 !important;

        background-color: $progressFillColor;
        background: linear-gradient(180deg, saturate(darken($themePinkBackgroundColor, 1.5), 20) 0%, lighten($themePinkBackgroundColor, 1%) 50%);
        background: linear-gradient($fillDeg, saturate(darken($themePinkBackgroundColor, 1.5), 20) 0%, lighten($themePinkBackgroundColor, 1%) 50%);
        border-top: 2px solid saturate(darken($themePinkBackgroundColor, 3.5), 2);
    }
}

.ProgressCard.ColorPurple {
    $borderColor: saturate(darken($themePurpleBackgroundShadowColor, 3%), 10%);
    $fontColor: saturate(lighten($themePurpleFontColor, 8%), 0%);
    $progressFillColor: lighten($themePurpleBackgroundColor, .5%);

    border: 2px solid $borderColor;

    .label {
        .label-content {
            color: $fontColor !important;
        }
    }

    .progress-fill {
        border-radius: 8px 0 0 8px !important;

        background-color: $progressFillColor;
        background: linear-gradient($fillDeg, saturate(darken($themePurpleBackgroundColor, 1.5), 20) 0%, lighten($themePurpleBackgroundColor, 1%) 50%);
        border-top: 2px solid saturate(darken($themePurpleBackgroundColor, 3.5), 2);
    }
}



.ProgressRow {

    position: fixed;
    //right: -114px;
    width: 648px;

    .cards {
        display: flex;
        margin: 107.5px 0 0 6.5px;
        margin: 0px 0 0 6.5px;

        //transform: scale(.95);
    }

    .SuggestionOption.Open {
        border: 2px dashed darken(#fffcf7, 8%);
        // height: 80px;
        // width: 80px;
        //border-radius: 12px;
        //width: 170px;
        //height: 122px;
        width: 158px;
        margin: 12px 8px 0 8px;
        opacity: .75;

        &:hover {
            transform: scale(1.05) !important;
        }
    }

    .SuggestionOption.OpenPlaceholder {
        position: relative;
        top: -122px;
        z-index: 100;
    }
}

.OverCard {
    .HiddenCard {
        opacity: 1 !important;
        top: -9px !important;
        transform: scale(1.1) !important;
        z-index: 10000;
    }
}

.HiddenCard {
    position: absolute;
    top: 70px !important;
    left: -1px;
    opacity: 0;
    transition: .25s !important;

    .ProgressCounter {
        margin-top: 4px;
        transform: scale(1.02);
        opacity: 1 !important;
    }
}

$rowTop: 47.0px + 107.5px;
$rowHeight: 147.5px;

.ProgressRow.ColorYellow {
    top: $rowTop;
}

.ProgressRow.ColorPink {
    top: $rowTop + $rowHeight;

    .SuggestionOption.Open {
        border: 2px dashed darken($themePinkBackgroundColor, 5%);
    }
}

.ProgressRow.ColorPurple {
    top: $rowTop + ($rowHeight * 2);

    .SuggestionOption.Open {
        border: 2px dashed darken($themePurpleBackgroundColor, 5%);
    }
}

.AnimatingThoughtStatusFadeInPaperCard {
    .HiddenCard {
        opacity: 1 !important;
        top: -9px !important;
        transform: scale(1.075) !important;
        transition: .375s !important;
        z-index: 10000;
    }
}

.AnimatingThoughtStatusPushInPaperCard {
    .HiddenCard {
        opacity: 0 !important;
        top: -9px !important;
        transform: scale(.4) !important;
        transition: .8s !important;
        z-index: 10000;
    }

    .GreatJobMessage {
        opacity: 1 !important;
    }
}

.ProgressRow.ColorPink {
    .GreatJobMessage {
        .message-label {
            color: $themePinkMediumFontColor !important;
        }
    }
}

.ProgressRow.ColorPurple {
    .GreatJobMessage {
        .message-label {
            color: $themePurpleMediumFontColor !important;
        }
    }
}


@keyframes messageBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0) rotate(0deg);
    }

    0% {
        opacity: 0;
    }

    40% {
        transform: translateY(-8px) rotate(0deg);
        opacity: 1;
    }

    60% {
        transform: translateY(-4px) rotate(0deg);
        opacity: 1;
    }

    70% {
        transform: translateY(0px) rotate(0deg);
        opacity: 1;
    }

    100% {
        transform: translateY(0px) rotate(0deg);
        opacity: 0;
    }
}