@import 'global';

// Was 788px. Not sure yet.


$width: 580px;

.NegativeGrid {
    flex: 1;
    width: $width;
    min-width: $width;
    max-width: $width;

    margin-left: -6px;
    margin-top: -28px;
    margin-top: -14px;
    margin-top: 8px;
    //margin-top: -6px;

    // position: relative;
    // top: 0px;
    // left: -24px;

    //padding-left: -28px + 0px + $sidebarWidth + $sidebarSpace;

    transition: .3s;

    transform-origin: top left;

    margin-top: 6.5px;

    transform: scale(.9075);
    transform: scale(.90);
    transform: scale(.885);


    //margin-left: -p;

    z-index: 1000;

    //zoom: 1.05;

    //opacity: .6;

    .greeting-content {
        position: fixed;
        left: -420px;
        top: 64px;
        margin-top: -58px;
        transform: scale(1.35);
        transform-origin: top left;

        padding-bottom: 20.5px;

        opacity: 0;

        .greeting-bubble {
            display: flex;
            border-radius: 18px;
            padding: 0px 0 0 26px;
        }

        .plant-img {
            width: 20px;
            align-self: center;
        }

        .greeting-label {
            padding: 6px 0 0 4px;
            font-family: $serifFamily;
            font-size: 13pt;
            font-weight: 700;

            color: #333;
            color: $mainHeadingColor;

            .date-phrase {
                color: desaturate(darken(#ffeca7, 10%), 30%);
                //color: #aaa;
                opacity: .8;
                color: #fff;
            }
        }
    }

    .headings {
        position: relative;
        //opacity: 0;
    }

    .heading-column {
        position: absolute;
        top: 22px;
        left: 28px;
        left: 15px;
        width: 334px;
        width: 548px;
        //border-bottom: 2.5px solid rgba($mainHeadingColor, .2);
        //border-bottom: 2px solid darken($dropBackgroundColor, 12%);

        //border-bottom: 2.5px solid #eee;
        padding-bottom: 6px;

        //font-family: $serifFamily;
        font-size: 11.5pt;
        font-weight: 500;

        color: darken($mainHeadingColor, 6%);

        //font-family: $serifFamily;
        font-size: 11.75pt;
        font-weight: 500;
        //color: darken($dropBackgroundColor, 52%);
        //color: $themeTealFontColor;

        // color: darken($themeYellowBackgroundColor, 60%);
        // font-size: 15pt;

        //color: #222;
        //opacity: 0;
        //color: #333;

        //color: darken(#ebc43d, 14%);
        //border-bottom: 2.5px solid lighten(#ebc43d, 30%);

        //color: darken($dropBackgroundColor, 50%);
        //color: #333;
        //color: lighten(#920041, 50%);
        //color: #bbb !important;
        //opacity: 0.8;

        text-align: left;

        //opacity: .55;
        top: 24.5px;
        //opacity: 0;

        // width: 100px;
        // background-color: lighten($dropBackgroundColor, 1%);
        // border: 0;
        // border-radius: 100px;
        // padding: 8px 18px;

        //border-bottom: 0;

        font-size: 12.25pt;
        border-bottom: 2.5px solid darken($dropBackgroundColor, 6%);
        border-bottom: 2px solid darken($dropBackgroundColor, 5%) !important; // was 12%, might be better
        font-family: $serifFamily;

        //color: darken($dropBackgroundColor, 36%);
        //color: #222;

        //text-align: center;
        letter-spacing: 1.5px;
        font-weight: 700;
        font-size: 9.5pt;
        text-transform: uppercase;
        font-family: $sansFamily;
        padding: 6px 0;

        //background-color: darken($dropBackgroundColor, 0%);
        // border: 0;
        // border-radius: 100px;
        // padding: 8px 0;
        // font-size: 10.25pt;
        // top: 20.5px;
        // width: 170px;
        // left: 6px;
        //opacity: 0;
    }

    .heading-column-2 {
        //display: none;
        left: 598.0px;
        width: 533px;
        //color: darken(#a8dfe8, 14%);
        //border-bottom: 1.5px solid lighten(#a8dfe8, 10%);
    }

    .refresh-container2 {
        //opacity: 0;
        //margin-left: auto;
        //flex: 1;
        //background-color: red;
        margin-left: auto;
        margin-left: 6.5px;
        margin-right: 14px;
        margin-top: 146px;
        margin-top: 143px;
        margin-top: 62px;
        margin-left: 678px;

        margin-top: 72px;
        margin-left: 345px;

        margin-top: 62px;
        margin-left: 495px;

        // margin-top: -263px;
        // margin-left: 920px;
        //margin-top: 23px;

        margin-top: 88px;
        margin-left: 90px;
        margin-left: 102px;
    }

    .RefreshButton {
        opacity: 1;
        width: 372px;
        width: 348px;
        height: 54px;
        background-color: lighten($dropBackgroundColor, 24%);
        background-color: transparent;
        border: 0;
        border-radius: 100px;
        margin-top: -8px !important;
        transition: .2s;
        transform: scale(1.65);

        //box-shadow: .5px .5px 1px 1px rgba(darken($backgroundColor, 18.5%), .5);
        //border: 2.5px solid darken($dropBackgroundColor, 0%);
        background-color: lighten($dropBackgroundColor, 1.5%);
        background-color: lighten($themeYellowBackgroundColor, 1.5%);

        .refresh-icon-img {
            width: 24px;
            margin-top: 15px;
        }
    }

    .RefreshButton:hover {
        cursor: pointer !important;
        transform: scale(1.7);
        box-shadow: 1px 1px 2px 1px darken(#f7eee3, 0%);
        background-color: darken($themeYellowBackgroundColor, 0%);
    }
}